import SnackbarLoader from "./snackbar/SnackbarLoader";
import {useState, useContext} from "react";
import axiosAuth from "../../utils/axiosWithAuth";
import { TextField } from "@material-ui/core";
import PrimaryColorLoader from "../PrimaryColorLoader";
import TextractContext from "../../contexts/TextractContext";
import { useIntakeAccounts } from "../../contexts/AccountContext";

export default function TextractUploader({utility_company_id, account, selectNextRow, fetchParams, role, setSnackbar}) {
  const [loading, setLoading] = useState(false)
  const {markAsChecked, fetchAccounts} = useIntakeAccounts()
  const onFileLoad = (e) => {
    const {files} = e.target;
    console.log(files)
    if (e.target.files[0]) {
      if (utility_company_id == 'null' && !window.confirm(`You are about to upload files without specifying a utility company. If you know the provider from whom the bills came, please cancel and select a utility company from the selector before uploading.`)) {
        e.target.value = "";
        return
      }
      setLoading(true)
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('fileData[]', files[i]);
      }
      formData.append("utility_company_id", utility_company_id == "null" ? null : utility_company_id)

      if (account) formData.append("utility_account_id", account.id == "null" ? null : account.id)
      
       
      setSnackbar({message: "Uploading...", severity: "info",  loader: <SnackbarLoader /> })
      axiosAuth().post("admin/textract_bills/upload", formData, {headers: {"Content-Type": "multipart/form-data"}})
      .then(r => {
        console.log(r)
        setSnackbar({message: r.data.message || "Upload complete", severity: "success"})
        if (account){ 
          markAsChecked(account,fetchParams, _ => selectNextRow())
        }
        e.target.value = "";
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        setSnackbar({message: "Extraction Failed.", severity: "error"})
        console.log(err)
        e.target.value = "";
        
      })
    }
  }
  return(
    <div className="uploader-container" style={{display: 'inline-flex'}}>
      <TextField
        fillWidth
        inputProps={{
          multiple: true
        }}
        style={{display: 'block'}}
        name='fileData[]'
        type='file' 
        onChange={onFileLoad} 
        label="Upload File"
        InputLabelProps={{
          shrink: true
        }}
      />
      <PrimaryColorLoader
        style={{opacity: loading ? 1 : 0, marginLeft: "8px"}}
        loaderType="Grid"
        height={40}
        width={40} 
      /> 
    </div>
  )
}